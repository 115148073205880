<template lang="pug">
  .d-flex.flex-column.flex-grow-1.overflow-hidden(v-if="selectedObjects.length > 0")
    b-button.align-self-end(v-if="variantList === 'reporting' && view !== 0",
      :pressed.sync="openStatusGroups",
      variant="primary",
      size="sm") {{openStatusGroups ? 'Скрыть все группы' : 'Раскрыть все группы'}}
    .d-flex.align-items-center.justify-content-between.p-2.item-info.title(v-if="variantList !== 'outMonitoring'")
      .d-flex.align-items-center
        light-checkbox(:checked="allSelectedFoundObjects", @change="toggleAllSelectedFoundObjects")
        a.mr-1(v-for="(method, methodName) in sortMethods" href="#", @click="switchSortingMethod(methodName)" v-b-popover.hover.top="method.title")
          b-icon(:icon="method.icon", :variant="method.variant")
        .d-inline-block.mr-1.text-body(style="cursor: pointer" @click="switchSortingMethod(currentSortingMethodName)") {{ currentSortingMethod.name }}
        b-icon(:icon="currentSortingMethodValue ? 'caret-up-fill' : 'caret-down-fill'", variant="dark")
      div
        b-button.p-0(v-if="variantList === 'monitoring'", variant="link", @click="visibleAllObjects")
          b-icon(:icon="allVisible ? 'eye' : 'eye-slash'", variant="dark")
    template(v-if="variantList === 'outMonitoring'")
      light-checkbox.ml-1.mb-2(v-if="view === 0" :checked="allSelectedFoundObjects", @change="toggleAllSelectedFoundObjects") {{$t('tracks.objects')}}
      div.d-flex.align-items-center.justify-content-between.p-1.mb-1(v-if="view !== 0")
        light-checkbox(:checked="allSelectedFoundObjects", @change="toggleAllSelectedFoundObjects") {{$t('tracks.objects')}}
        b-button(:pressed.sync="openStatusGroups", variant="primary", size="sm") {{openStatusGroups ? 'Скрыть все группы' : 'Раскрыть все группы'}}
    //- div.flex-grow-1.overflow-auto(class="list" @mouseleave="variantList !== 'outMonitoring' ? altRemoveTooltip() : null",)
    q-virtual-scroll(ref="virtualScroll", v-if="rr && currentList.length > 0", @mouseleave="hideTooltip(hoverObject)", @virtual-scroll="scrolled($event)", :items="currentList", separator, virtual-scroll-item-size="34", virtual-scroll-slice-size="100", virtual-scroll-slice-ratio-before="1", virtual-scroll-slice-ratio-after="1")
      //- template(v-for="(item, index) in currentList")
      template(v-slot="{ item, index }")
        template(v-if="item.group !== undefined")
          button.btn.btn-sm.rounded-0.w-100.border-bottom.group-header(
            v-if="item.group.Objects.length > 0",
            :key="item.index",
            :class="`.btn-${!closedGroups.has(item.index) ? 'white' : 'light'}`",
            :active="!closedGroups.has(item.index)",
            @click="switchGroupOpenState(item.index)")
            .d-flex.w-100.align-items-center
              .pr-2
                svg.bi-chevron-down.b-icon.bi.text-success(v-if="!closedGroups.has(item.index)", viewBox="0 0 16 16", width="1em", height="1em", focusable="false", role="img", aria-label="chevron down", xmlns="http://www.w3.org/2000/svg", fill="currentColor")
                  path(fill-rule="evenodd", d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z")
                svg.bi-chevron-right.b-icon.bi.text-success(v-else, viewBox="0 0 16 16", width="1em", height="1em", focusable="false", role="img", aria-label="chevron right", xmlns="http://www.w3.org/2000/svg", fill="currentColor")
                  path(fill-rule="evenodd", d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z")
              .pr-0(@click.stop)
                light-checkbox.checkbox-without-animation(:checked="checkedGroups.get(item.index)", @change="(v) => onCheckedObjectsGroup(item.group, v)")
              .objects-list--name.w-100.d-flex.pr-2.align-items-center.font-weight-bolder {{ `${item.group.Name} (${item.objects.length})` }}
              button.btn.btn-sm.btn-link.p-0.ml-2(v-if="item.group.Edit && isAdmin && variantList === 'monitoring' && view === 1", @click.stop="$bvModal.show(`edit-shared-group-obj-${item.group.Id}`)")
                svg.bi-pencil.b-icon.bi.text-dark(viewBox="0 0 16 16", width="1em", height="1em", focusable="false", role="img", aria-label="pencil", xmlns="http://www.w3.org/2000/svg", fill="currentColor")
                  path(d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z")
              b-modal(:id="`edit-shared-group-obj-${item.group.Id}`", :title="$t('monitoring.editsharedgroup')", centered, body-class="objects-modal", @show="nameGroup = item.group.Name", @ok="modalEditGroup(item.group.Id, nameGroup, currentAccountingUnit)")
                b-row
                  b-col(md="12")
                    b-form-input(v-model="nameGroup", required, autofocus, :placeholder="$t('monitoring.enternamegroup')", type="text")
                template(v-slot:modal-footer="{ ok, hide }")
                  .w-100.d-flex.modal-btn
                    b-button.mr-2.float-left(@click="ok()", variant="success", size="sm", :disabled="nameGroup === '' ? 'disabled' : null") {{$t('actionsList.change')}}
                    b-button.float-right(@click="hide()", variant="danger", size="sm") {{$t('actionsList.cancel')}}
              button.btn.btn-sm.btn-link.p-0.ml-2(v-if="item.group.Edit && isAdmin && variantList === 'monitoring' && view === 1", @click.stop="deleteObjectsGroup(item.group.Id, currentAccountingUnit)")
                svg.bi-trash.b-icon.bi.text-danger(viewBox="0 0 16 16", width="1em", height="1em", focusable="false", role="img", aria-label="trash", xmlns="http://www.w3.org/2000/svg", fill="currentColor")
                  g
                    path(d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z")
                    path(fill-rule="evenodd", d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z")
              b-button.p-0.ml-2(v-if="item.group.Edit && variantList === 'monitoring' && view === 2", variant="link", size="sm", @click.stop="$bvModal.show(`edit-private-group-obj-${item.group.Id}`)")
                b-icon(icon="pencil", variant="dark")
              b-modal(:id="`edit-private-group-obj-${item.group.Id}`", :title="`Редактрирование приватной группы`", centered, body-class="objects-modal", @show="nameGroup = item.group.Name", @ok="modalEditGroupPrivate(item.group.Id, nameGroup)")
                b-row
                  b-col(md="12")
                    b-form-input(v-model="nameGroup", required, autofocus, :placeholder="$t('monitoring.enternamegroup')", type="text")
                template(v-slot:modal-footer="{ ok, hide }")
                  .w-100.d-flex.modal-btn
                    b-button.mr-2.float-left(@click="ok()", variant="success", size="sm", :disabled="nameGroup === '' ? 'disabled' : null") {{$t('actionsList.change')}}
                    b-button.float-right(@click="hide()", variant="danger", size="sm") {{$t('actionsList.cancel')}}
              b-button.p-0.ml-2(v-if="item.group.Edit && variantList === 'monitoring' && view === 2", variant="link", size="sm", @click.stop="deleteObjectsGroupPrivate(item.group.Id)")
                b-icon(icon="trash", variant="danger")
              button.btn.btn-sm.btn-link.p-0.ml-2(v-if="variantList === 'monitoring'", @click.stop="visibleObjectGroup(item.group)")
                svg.b-icon.bi.text-dark(viewBox="0 0 16 16", width="1em", height="1em", focusable="false", role="img", :aria-label="item.group.Visible ? 'eye' : 'eye-slash'", xmlns="http://www.w3.org/2000/svg", fill="currentColor",
                  :class="{ 'bi-eye': item.group.Visible, 'bi-eye-slash': !item.group.Visible }")
                  g(v-if="item.group.Visible")
                    path(d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z")
                    path(d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z")
                  g(v-else)
                    path(d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z")
                    path(d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z")
                    path(d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z")
        template(v-else)
          button.w-100.border-bottom.btn.btn-light.btn-sm.rounded-0(
            :key="item.index",
            :target-id="item.value.id",
            :title="item.value.data.Name",
            :pressed="variantList !== 'outMonitoring' ? item.value.id === activeObject : null",
            @mouseenter="variantList !== 'outMonitoring' ? altSetTooltip($event.target, item.value, item.value.id) : null",
            @click="variantList !== 'outMonitoring' ? setActiveObject(item.value.id, index) : null"
          )
            .d-flex.w-100.align-items-center
              .pr-2(v-if="item.groupId")
                .grouped-padding
              .pr-0(@click.stop)
                .custom-control.custom-checkbox
                  input.custom-control-input(type="checkbox", :checked="checkedObjectsSet.has(item.value.id)", @change="onCheckedObject(item.value, !checkedObjectsSet.has(item.value.id))", :id="`__chk${index}__`")
                  label.custom-control-label(:for="`__chk${index}__`")
              .pr-2
                span.b-avatar.b-avatar-sm.badge-light.rounded-circle
                  span.b-avatar-img(v-if="selectedObjectsImages[item.value.id]")
                    img(:src="selectedObjectsImages[item.value.id]" alt="avatar")
                  svg.bi-cursor-fill.b-icon.bi(v-else, viewBox="0 0 16 16", width="1em", height="1em", focusable="false", role="img", aria-label="cursor fill", aria-hidden="true", alt="avatar", xmlns="http://www.w3.org/2000/svg", fill="currentColor")
                    path(d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z")
              .objects-list--name.w-100.d-flex.pr-2.align-items-center
                span.objects-list--name.text-nowrap {{ item.value.data.Name }}
                span.badge.ml-1.badge-secondary(v-if="isAdmin && showAccountingUnits") {{item.value.data.AccountingUnitName}}
              svg.b-iconstack.mr-2.b-icon.bi(viewBox="0 0 16 16", width="1em", height="1em", focusable="false", role="img", aria-label="icon", xmlns="http://www.w3.org/2000/svg", fill="currentColor", style="margin-bottom: 1px; font-size: 87.75%;")
                title {{ $t('monitoring.stateobject') }}
                g
                  svg.bi-circle-fill.b-icon.bi(viewBox="0 0 16 16", fill="currentColor", :style="switchStateColor(item.value.reactive.color)")
                    circle(cx="8", cy="8", r="8")
                  svg.bi-cursor-fill.text-white.b-icon.bi(v-if="item.value.reactive.speed > item.value.data.MinSpeed && (item.value.reactive.color === 'Green')", viewBox="0 0 16 16", fill="currentColor")
                    g(transform="translate(8 8) scale(0.7 0.7) translate(-8 -8)")
                      path(d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103z")
              button.btn.p-0.btn-link.btn-sm.mr-1(:title="$t('monitoring.visibleobjectinmap')", type="button", @click.stop="visibleObject(item.value.id, !item.value.visible)")
                svg.b-icon.bi.text-dark(viewBox="0 0 16 16", width="1em", height="1em", focusable="false", role="img", :aria-label="item.value.visible ? 'eye' : 'eye-slash'", xmlns="http://www.w3.org/2000/svg", fill="currentColor",
                  :class="{ 'bi-eye': item.value.visible, 'bi-eye-slash': !item.value.visible }")
                  g(v-if="item.value.visible")
                    path(d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z")
                    path(d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z")
                  g(v-else)
                    path(d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z")
                    path(d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z")
                    path(d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z")
              template(v-if="monitoringRemoveButton && (variantList == 'monitoring')")
                svg.bi-x.b-icon.bi.text-danger(viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="x" xmlns="http://www.w3.org/2000/svg" fill="currentColor" :class="{['b-icon-animation-fade']: deletingObjects.includes(item.value.id)}" @click.stop="!deletingObjects.includes(item.value.id) && deleteObject(item.value)")
                  title {{ $t(deletingObjects.includes(item.value.id) ? 'monitoring.removingobject' : 'monitoring.removeobject') }}
                  g(transform="translate(8 8) scale(1.7 1.7) translate(-8 -8)")
                    path(d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z")
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import switchStateColor from '~/mixins/switchStateColor';
import LightCheckbox from '../LightCheckbox';

const colorValues = {
  Green: 1,
  Yellow: 2,
  Red: 3,
  Grey: 4,
};

function colorValue (color) {
  return colorValues[color] || 100;
}

export default {
  name: 'ListGroupsObjects',
  components: { LightCheckbox },
  mixins: [switchStateColor],
  props: {
    variantList: {
      type: String,
      default: '',
    },
    sharedGroups: {
      type: Array,
      default () {
        return [];
      },
    },
    privateGroups: {
      type: Array,
      default () {
        return [];
      },
    },
    foundObjects: {
      type: Array,
      default () {
        return [];
      },
    },
    activeObject: {
      type: Number,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    rr: {
      type: Boolean,
      default: true,
    },
    hoverObject: {
      type: Object,
      default () {
        return {};
      },
    },
    selectedObjects: {
      type: Array,
      default () {
        return [];
      },
    },
    view: {
      type: Number,
      default: 0,
    },
    currentAccountingUnit: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      openStatusGroups: true,
      checkedObjectsSet: new Set(),
      checkedGroups: new Map(),
      closedSharedGroups: new Set(),
      closedPrivateGroups: new Set(),
      sortMethods: {
        name: {
          name: this.$t('monitoring.sorting.nameobject'),
          title: this.$t('monitoring.sorting.nameobjectTitle'),
          comparator: this.comparatorByName,
          icon: 'sort-alpha-down',
          variant: 'dark',
          defaultAsc: true,
        },
        state: {
          name: this.$t('monitoring.sorting.state'),
          title: this.$t('monitoring.sorting.stateTitle'),
          comparator: this.comparatorByState,
          icon: 'circle-fill',
          variant: 'success',
          defaultAsc: true,
        },
        connectionTime: {
          name: this.$t('monitoring.sorting.connectionTime'),
          title: this.$t('monitoring.sorting.connectionTimeTitle'),
          comparator: this.comparatorByConnectionTime,
          icon: 'reception4',
          variant: 'success',
          defaultAsc: false,
        },
      },
      activeObjectId: null,
      allVisible: true,
      nameGroup: '',
      lastChecked: null,
      virtualScrollSliceRange: 0,
      clickCheckboxFlag: false,
      brakeSetScrollPosition: false,
      deletingObjects: [],
    };
  },
  computed: {
    ...mapGetters('listGroupsObjects', ['checkedObjectsOutMonitoring', 'scrollPositionMonitoring', 'scrollPositionOutMonitoring']),
    ...mapGetters('preferences', ['showAccountingUnits', 'ruleSortingObjects', 'monitoringRemoveButton']),
    ...mapState('objects', ['selectedObjectsImages']),
    indeterminate () {
      return this.checkedObjectsSet.length > 0 && this.checkedObjectsSet.length < this.foundObjectsId.size;
    },
    selectedGroups () {
      if (this.view === 1 && this.sharedGroups.length > 0) {
        return this.sharedGroups.filter(el => el.Show);
      }
      if (this.view === 2 && this.privateGroups.length > 0) {
        return this.privateGroups.filter(el => el.Show);
      }
      return [];
    },
    closedGroups () {
      if (this.view === 1 && this.sharedGroups.length > 0) {
        return this.closedSharedGroups;
      }
      if (this.view === 2 && this.privateGroups.length > 0) {
        return this.closedPrivateGroups;
      }
      return new Set();
    },
    groupedList () {
      let group = '';
      if (this.view === 1 && this.sharedGroups.length > 0) {
        group = 'sharedGroups';
      } else if (this.view === 2 && this.privateGroups.length > 0) {
        group = 'privateGroups';
      }

      return this.selectedGroups.map((el) => {
        const groupId = `group${group}_id${el.Id}`;
        const objectsOfTheGroup = el.Objects.filter(({ id }) => this.foundObjectsId.has(id)); // Очень затратная операция в больших списках
        return (
          {
            group: el,
            index: groupId,
            objects: this.sortObjects(objectsOfTheGroup.map(item => ({
              value: item,
              groupId,
              index: `${groupId}_item${item.id}`,
            }))),
          }
        );
      });
    },
    currentList () {
      if (this.groupedList.length > 0) {
        const newArr = this.groupedList.map(el => ([
          el,
          ...(this.closedGroups.has(el.index) ? [] : el.objects),
        ]));
        return newArr.flat();
      }
      return this.sortObjects(this.foundObjects.map(item => ({
        value: item,
        index: `_${item.id}`,
      })));
    },
    foundObjectsId () {
      return new Set(this.foundObjects.map(item => item.id));
    },
    allSelectedFoundObjects () { // null is indeterminate
      if (this.checkedObjectsSet.size === 0) {
        return false;
      }

      return (this.checkedObjectsSet.size === this.foundObjectsId.size) || null;
    },
    currentSortingMethodName () {
      return this.ruleSortingObjects?.type || 'name';
    },
    currentSortingMethodValue () {
      return this.ruleSortingObjects?.value ?? true;
    },
    currentSortingMethod () {
      return this.sortMethods[this.currentSortingMethodName] || this.sortMethods.name;
    },
  },
  watch: {
    view (n) {
      if (n === 0 && this.variantList !== 'monitoring') {
        this.currentList.sort(this.sortingCheckedObject);
      }
    },
    openStatusGroups (n) {
      this.setOpenStatusGroups(n);
    },
    groupedList () {
      this.updateGroupSelection();
    },
    'hoverObject.data': {
      handler (to, from) {
        if ((to === from) && (this.hoverObject.id !== this.activeObjectId) && (to?.address === null)) {
          this.$store.dispatch('objects/updateAddress', { id: this.hoverObject.id, lat: to.lat, lon: to.lng });
        }
      },
      deep: true,
    },
  },
  mounted () {
    // console.debug('ListGroupsObjects mounted');
    if (this.view === 0 && this.variantList !== 'monitoring') {
      this.checkedObjectsSet = new Set(this.checkedObjectsOutMonitoring.filter(id => this.selectedObjects.find(item => item.id === id)));
      this.updateSelection();
      this.updateGroupSelection();
      this.currentList.sort(this.sortingCheckedObject);
    }
    this.$nuxt.$on('mqttObjectModified', this.onMQTTModify);
  },
  updated () {
    // console.debug('ListGroupsObjects updated');
    if (this.$refs.virtualScroll && !this.brakeSetScrollPosition) {
      this.virtualScrollSliceRange = this.$refs.virtualScroll.virtualScrollSliceSizeComputed.total;
      this.setScrollPosition();
    }
    this.brakeSetScrollPosition = false;
  },
  activated () {
    // console.debug('ListGroupsObjects activated');
    if (this.$refs.virtualScroll) {
      this.virtualScrollSliceRange = this.$refs.virtualScroll.virtualScrollSliceSizeComputed.total;
      this.setScrollPosition();
    }
  },
  deactivated () {
    // console.debug('ListGroupsObjects deactivated');
  },
  beforeDestroy () {
    // console.debug('ListGroupsObjects beforeDestroy');
    this.setScrollPositionOutMonitoring(0);
    this.$nuxt.$off('mqttObjectModified', this.onMQTTModify);
  },
  methods: {
    ...mapMutations('listGroupsObjects', ['setScrollPositionMonitoring', 'setScrollPositionOutMonitoring']),
    ...mapActions('preferences', ['setPreference']),
    onMQTTModify (id) {
      this.$refs.virtualScroll?.$forceUpdate();
    },
    isGroupChecked (groupId, groupItems) {
      if (groupId && (Array.isArray(groupItems)) && (groupItems.length > 0)) {
        const result = this.checkedObjectsSet.has(groupItems[0].value.id);
        // eslint-disable-next-line no-restricted-syntax
        for (const item of groupItems) {
          if (result !== this.checkedObjectsSet.has(item.value.id)) {
            return null;
          }
        }
        return result;
      }
      return false;
    },
    isGroupCheckedByGroup (group) {
      return this.isGroupChecked(group.index, group.objects);
    },
    isGroupCheckedByIndex (index) {
      return this.isGroupChecked(index, this.groupedList.find(obj => obj.index === index)?.objects);
    },
    updateSelection () {
      this.checkedObjectsSet = new Set(this.checkedObjectsSet.keys());
      this.$emit('change-checked-objects', [...this.checkedObjectsSet.keys()]);
    },
    updateGroupSelection (index) {
      if (index) {
        this.checkedGroups.set(index, this.isGroupCheckedByIndex(index));
      } else {
        this.groupedList.forEach((group) => {
          this.checkedGroups.set(group.index, this.isGroupCheckedByIndex(group.index));
        });
      }
      this.checkedGroups = new Map(this.checkedGroups);
    },
    toggleAllSelectedFoundObjects () {
      if (!this.allSelectedFoundObjects) {
        [...this.foundObjectsId].forEach((item) => {
          this.checkedObjectsSet.add(item);
        });
      } else {
        [...this.foundObjectsId].forEach((item) => {
          this.checkedObjectsSet.delete(item);
        });
      }
      this.updateSelection();
      this.updateGroupSelection();
    },
    switchGroupOpenState (index) {
      if (this.view === 1 && this.sharedGroups.length > 0) {
        if (this.closedSharedGroups.has(index)) {
          this.closedSharedGroups.delete(index);
        } else {
          this.closedSharedGroups.add(index);
        }
        this.closedSharedGroups = new Set([...this.closedSharedGroups]);
      }
      if (this.view === 2 && this.privateGroups.length > 0) {
        if (this.closedPrivateGroups.has(index)) {
          this.closedPrivateGroups.delete(index);
        } else {
          this.closedPrivateGroups.add(index);
        }
        this.closedPrivateGroups = new Set([...this.closedPrivateGroups]);
      }
    },
    setScrollPosition () {
      // console.debug('setScrollPosition');
      if (this.variantList === 'monitoring' && this.foundObjects.length + this.virtualScrollSliceRange >= this.scrollPositionMonitoring && !this.clickCheckboxFlag) {
        this.$refs.virtualScroll.scrollTo(this.scrollPositionMonitoring, 'start-force');
      } else if (this.variantList === 'outMonitoring' && this.foundObjects.length + this.virtualScrollSliceRange >= this.scrollPositionOutMonitoring && !this.clickCheckboxFlag) {
        this.$refs.virtualScroll.scrollTo(this.scrollPositionOutMonitoring, 'start-force');
      }
      this.clickCheckboxFlag = false;
    },
    scrolled (e) {
      // console.debug('scrolled');
      if (this.variantList === 'monitoring' && this.$refs.virtualScroll) {
        this.setScrollPositionMonitoring(e.index);
        this.shiftSelectCheckboxes();
      } else if (this.variantList === 'outMonitoring' && this.$refs.virtualScroll) {
        this.setScrollPositionOutMonitoring(e.index);
        this.shiftSelectCheckboxes();
      }
    },
    shiftSelectCheckboxes () {
      const tabContent = document.querySelector('.list-groups-tabs > .tab-content');
      const pointSearch = tabContent ?? document;
      const checkboxes = Array.from(pointSearch.querySelectorAll('.list-groups-checkbox > .custom-control-input'));
      checkboxes.forEach((item) => {
        item.addEventListener('click', (e) => {
          // console.debug('click');
          this.clickCheckboxFlag = true;
          const currentListWithoutGroups = this.currentList.filter(_item => !_item.group);
          const index = currentListWithoutGroups.findIndex(_item => _item.value.id === Number(e.target.value));
          if (!this.lastChecked) {
            this.lastChecked = {
              object: e.target,
              index,
            };
          }
          if (e.shiftKey) {
            const start = index;
            const end = this.lastChecked.index;
            if (start === end) {
              return;
            }

            const dedicatedCheckboxes = currentListWithoutGroups.map(checkbox => checkbox.value.id).slice(Math.min(start, end), Math.max(start, end) + 1);
            if (e.target.checked) {
              dedicatedCheckboxes.forEach((checkbox) => {
                this.checkedObjectsSet.add(checkbox);
              });
            } else {
              dedicatedCheckboxes.forEach((checkbox) => {
                this.checkedObjectsSet.remove(checkbox);
              });
            }
            this.updateSelection();
          }
          this.lastChecked = {
            object: e.target,
            index,
          };
        });
      });
    },
    setOpenStatusGroups (openStatusGroups) {
      this.currentList.forEach((item) => {
        if (item.group !== undefined) {
          if (openStatusGroups) {
            // eslint-disable-next-line
            item.group.Open = true;
          } else {
            // eslint-disable-next-line
            item.group.Open = false;
          }
        }
      });
    },
    checkGroupSelectIndeterminate (group) {
      const selected = group.Objects.filter(v => this.checkedObjectsSet.has(v.id)).map(v => v.id);
      return selected.length > 0 && selected.length < group.Objects.length;
    },
    filterGroup (objects) {
      return objects.filter(item => this.foundObjectsId.has(item.data.Id));
    },
    altRemoveTooltip () {
      this.hoverObject.timeout = setTimeout(() => {
        this.hoverObject.show = false;
      }, 100);
    },
    altSetTooltip (target, data, id) {
      if (this.hoverObject.timeoutInit) {
        clearTimeout(this.hoverObject.timeoutInit);
      }

      this.hoverObject.timeoutInit = setTimeout(() => {
        this.setTooltip(target, data, id);
      }, 10);
    },
    setTooltip (target, obj, id) {
      this.hoverObject.id = id;
      this.hoverObject.target = target;
      this.hoverObject.show = !!this.selectedObjects.length;
      const { data, reactive } = obj;

      this.hoverObject.data = reactive;
      this.hoverObject.units = data.Sensors;

      if (this.hoverObject.data.address === null) {
        this.$store.dispatch('objects/updateAddress', { id, lat: reactive.lat, lon: reactive.lng });
      }
    },
    setActiveObject (id, position) {
      this.$emit('set-active-object', this.activeObjectId = id, position);
    },
    onCheckedObject (object, checked) {
      // console.debug('change checked');
      if (checked) {
        this.checkedObjectsSet.add(object.id);
      } else {
        this.checkedObjectsSet.delete(object.id);
      }
      this.updateSelection();
      this.updateGroupSelection();
    },
    setCheckedObjects (arr) { // Не удалять! Это функция используется!
      this.checkedObjectsSet = new Set(arr);
      this.updateSelection();
      this.updateGroupSelection();
    },
    onCheckedObjectsGroup (group, selected) {
      const obj = group.Objects.map(v => v.id).filter(item => this.foundObjectsId.has(item));
      if (selected) {
        obj.forEach((id) => {
          this.checkedObjectsSet.add(id);
        });
      } else {
        obj.forEach((id) => {
          this.checkedObjectsSet.delete(id);
        });
      }
      // eslint-disable-next-line no-param-reassign
      group.checked = selected;
      this.updateSelection();
      this.updateGroupSelection();
    },
    switchSortingMethod (methodName) {
      this.setPreference({ ruleSortingObjects: JSON.stringify({ type: methodName, value: methodName === this.currentSortingMethodName ? !this.currentSortingMethodValue : this.sortMethods[methodName].defaultAsc }) });
    },
    sortingCheckedObject (a, b) {
      const aSelected = this.checkedObjectsSet.has(a.value.id);
      const bSelected = this.checkedObjectsSet.has(b.value.id);
      if (aSelected === bSelected) {
        return 0;
      }
      if (aSelected) {
        return -1;
      }
      return 1;
    },
    comparatorByName (a, b, asc) {
      const a1 = a.value.data.Name.toLowerCase();
      const b1 = b.value.data.Name.toLowerCase();
      return asc ?? this.currentSortingMethodValue ? a1.localeCompare(b1) : -a1.localeCompare(b1);
    },
    comparatorByState (a, b, asc) {
      const result = colorValue(a.value.reactive.color) - colorValue(b.value.reactive.color);
      if (!result) {
        return this.comparatorByName(a, b, true);
      }
      return asc ?? this.currentSortingMethodValue ? result : -result;
    },
    comparatorByConnectionTime (a, b, asc) {
      const aDate = new Date(a.value.reactive.lastConnectionTime?.getTime() || 0);
      const bDate = new Date(b.value.reactive.lastConnectionTime?.getTime() || 0);
      return asc ?? this.currentSortingMethodValue ? aDate - bDate : bDate - aDate;
    },
    sortObjects (array) {
      this.brakeSetScrollPosition = true;
      return array.sort(this.currentSortingMethod.comparator);
    },
    visibleAllObjects () {
      this.allVisible = !this.allVisible;
      const objects = this.selectedObjects.map(el => el.id);
      if (this.allVisible) {
        this.$store.dispatch('objects/visibleItems', objects);
      } else {
        this.$store.dispatch('objects/unvisibleItems', objects);
      }
    },
    hideTooltip () {
      if (this.hoverObject) {
        if (this.hoverObject.timeout) {
          clearTimeout(this.hoverObject.timeout);
        }
        this.hoverObject.timeout = setTimeout(() => {
          this.hoverObject.show = false;
        }, 600);
      }
    },
    visibleObject (id, visible) {
      const type = visible ? 'visibleItems' : 'unvisibleItems';
      this.$store.dispatch(`objects/${type}`, [id]);
    },
    visibleObjectGroup (_group) {
      const group = _group;
      group.Visible = !group.Visible;

      const type = group.Visible ? 'visibleItems' : 'unvisibleItems';
      this.$store.dispatch(`objects/${type}`, group.Objects.map(x => x.id));
    },
    modalEditGroup (id, name, accountingUnit) { // todo: спросить
      this.$store.dispatch('sharedobj/updatedSharedObjectsGroups', { id, group: { Name: name }, accountingUnit });
    },
    modalEditGroupPrivate (id, name) { // todo: спросить
      this.$store.dispatch('privateobj/updatedPrivateObjectsGroups', { id, group: { Name: name } });
    },
    deleteObjectsGroupPrivate (id) { // todo: спросить
      this.$store.dispatch('privateobj/deletedPrivateObjectsGroups', { id });
    },
    deleteObjectsGroup (id, accountingUnit) { // todo: спросить
      this.$store.dispatch('sharedobj/deletedSharedObjectsGroups', { id, accountingUnit });
    },
    deleteObject (object) {
      this.deletingObjects.push(object.id);
      this.$store.dispatch('objects/selectItems', { unselect: [object.id] }).finally(() => {
        this.deletingObjects.splice(this.deletingObjects.findIndex(ID => ID === object.id), 1);
        if (this.checkedObjectsSet.has(object.id)) {
          this.onCheckedObject(object, false);
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
//.scroll-height-monitoring {
//  height: calc(100vh - 183px);
//}
//.scroll-height-out-monitoring--small {
//  height: 30vh;
//}
//.scroll-height-out-monitoring--big {
//  height: 50vh;
//}
.group-header {
  background-color: #eaf3fb !important;
//  position: sticky;
//  top: 0;
//  z-index: 5;

  &:focus {
    box-shadow: none !important;
  }
}
.grouped-padding {
  width: 17.5px;
}
.checkbox-without-animation > label::before {
  transition: none;
}
.objects-list--name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.list {
  flex: 1;
  overflow-y: auto;
  //max-height: 100%;
  .list-group {
    //height: 100%;
  }

  &-objects {
    overflow-y: auto;
    max-height: 100%;
  }
}
.title {
  color: #444;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: baseline;
  .image {
    float: left;
    min-width: 20px;
    max-width: 20px;
  }
  .name {
    float: left;
    margin: 0 10px;
    overflow: hidden;
    min-width: 200px;
    max-width: 380px;
    font-size: 16px;
  }
  .title-area {
    margin: 0 10px;
    float: left;
    min-width: 100px;
    max-width: 300px;
    font-size: 14px;
    font-weight: 400;
    line-height: 33px;
  }
  .date {
    float: left;
    margin: 0 10px;
    overflow: hidden;
    min-width: 100px;
  }
  .distance {
    margin: 0 10px;
    overflow: hidden;
    min-width: 120px;
  }
  .color {
    float: left;
    margin: 0 10px;
    overflow: hidden;
    min-width: 80px;
  }
  .bubble {
    width: 14px;
    height: 14px;
    min-width: 14px;
    margin-bottom: -2px;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
  }

  .item-info {
    float: left;
    width: 100%;
    padding: 10px 0;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
  }
  .buttom-check {
    bottom: 0;
    width: 100%;
    background-color: white;
    flex: 1 1 0%;
  }
  .item-block-title {
    color: #444;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-content: center;
    align-items: center;
    white-space: nowrap;
  }
}
.modal-btn .btn-sm {
  width: 50%;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  padding: 5px;
  border-radius: 3px;
}
</style>
