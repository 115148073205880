import Address from '../entities/Address/Address';
import { asyncQueue } from '~/plugins/handlers/utils';

// TODO-ref - выделить cache в отдельный класс, тк повышает прозрачность кода
const cache = new Map();
const aq = asyncQueue();

function getAddressFromCache (cacheId) {
  if (!cacheId) {
    return null;
  }

  const cached = cache.get(cacheId);
  return cached || null;
}

export const state = () => ({
  prefixes: ['', 'a', 'b', 'c'],
  current: 0,
});

export const mutations = {
  increment (state) {
    state.current = state.current >= (state.prefixes.length - 1) ? 0 : state.current + 1;
  },
};

export const actions = {
  getPrefix ({ state, commit }) {
    const index = state.current;
    commit('increment');
    return state.prefixes[index];
  },

  async getAddress ({ rootState, dispatch }, { lat, lon, cacheOn = true }) {
    if (!lat || !lon) {
      return '';
    }
    let address;
    const addressIdForCash = Address.computeCacheId(lat, lon);

    address = cacheOn && getAddressFromCache(addressIdForCash);

    if (address instanceof Promise) {
      address = await address;
    } else if (!address) {
      const prefix = await dispatch('getPrefix');
      const promise = aq.queue(() => this.$http.$get(`https://nm4${prefix}.logexpert.ru/reverse?format=json&addressdetails=0&accept-language=${rootState.preferences.languageUser}&lat=${lat}&lon=${lon}`))
        .then((received) => {
          const addr = new Address(received.display_name);
          return addr.prepare({
            // eslint-disable-next-line no-bitwise
            doReverse: +rootState.preferences.addressView ^ addr.state.isReversed,
          });
        });
      cacheOn && cache.set(addressIdForCash, promise);
      address = await promise;

      // Note: в кеш сохраняются только оригинальные данные
      cacheOn && cache.set(addressIdForCash, address);
    }
    return address;
  },
};
