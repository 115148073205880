<template lang="pug">
  .root(:class="dragged" v-if="$store.state.user.data")
    //- b-alert(v-if="$store.state.user.apikey" variant="info" show dismissible fade)
      | Вы авторизованны как прокси пользователь
      b.ml-1 {{ $store.state.user.data ? $store.state.user.data.UserName : ''}}
    .header.container-fluid.bg-light
      .row.no-gutter.align-items-center.h-100.py-2
        .col.h-100.d-flex.align-items-center
          .h6.text-success.m-0
            img.img-fluid.logo-top(src="@/assets/img/logo.png", alt="LogExpert")
        .menu.col.h-100.d-flex.align-items-center.justify-content-center
          b-btn-group
            b-btn.menu-el(v-for="link in topMenu", :key="link.id", :to="link.url", variant="info") {{ link.name }}
        .col.h-100.d-flex.align-items-center.justify-content-end
          .mqtt-status(v-b-tooltip.hover :title="mqttStatus ? 'Вы получаете информацию об изменении состояний объекта' : 'Вы не получаете информацию об изменении состояний объекта'" :class="{ 'mqtt-status_disconnect': !mqttStatus }")
          a.mr-2(href="https://logexpert.ru/wiki/", rel="nofollow noopener noreferrer", target="_blank")
            b-icon.mb-0.h3(icon="question-circle-fill", variant="secondary", :style="{'margin-bottom': countAlerts > 0 ? '8px' : '0px', 'vertical-align': 'bottom'}")
          b-button.p-0.mr-4(v-b-toggle.sidebar-right, variant="link", size="sm" v-b-popover.hover="$t('menu.alerts')" style="position: relative; border-width: 0; height: 28px")
            b-icon(icon="bell-fill" style="color: #677079" font-scale="2")
            .alerts-counter(v-if="countAlerts")
              b-badge(pill, :style="{ backgroundColor: isRed ? '#ff0000' : '#bfbfbf' }") {{ countAlerts > 99 ? '99+' : countAlerts }}
          b-button-group
            b-dropdown(:variant="$store.state.user.apikey ? 'warning' : 'info'", right, toggle-class="text-decoration-none")
              template(#button-content)
                b-icon.mr-1(v-if="$store.state.user.apikey" icon="person-bounding-box")
                | {{ $store.state.user.data ? $store.state.user.data.UserName : ''}}
              b-dd-item(v-if="isAdmin || isAdminAU", :href="toAdminApp", rel="nofollow noopener noreferrer", target="_blank")
                b-icon.mr-1(icon="tools")
                | {{$t('menu.settings.administration')}}
              b-dd-divider(v-if="isAdmin || isAdminAU")
              b-dd-item-button(v-b-modal.modal-lg.person)
                b-icon.mr-1(icon="person")
                | {{$t('menu.settings.personal.data')}}
                b-modal#person(:title="$t('menu.settings.personal.data')", body-class="objects-modal", size="lg")
                  .justify-content-between.p-2
                    .d-flex.p-2
                      .modal-label {{$t('menu.settings.personal.user')}}:
                      p.h6 {{$store.state.user.data.UserName}}
                    .d-flex.p-2
                      .modal-label {{$t('menu.settings.personal.account')}}:
                      p.h6 {{accountingUnit}}
                    .d-flex.p-2
                      .modal-label {{$t('menu.settings.personal.admin')}}:
                      b-form-checkbox(v-model="isAdmin", disabled)
                    .d-flex.p-2
                      .modal-label {{$t('menu.settings.personal.adminAU')}}:
                      b-form-checkbox(v-model="isAdminAU", disabled)
                    .d-flex.p-2
                      .modal-label {{$t('menu.settings.personal.session')}}:
                      p.h6 {{$store.state.user.data.SessionId}}
                  template(v-slot:modal-footer="{ ok }")
                    b-button(@click="ok()", variant="success", size="sm") {{$t('ok')}}
              b-dd-item-button(v-b-modal.modal-lg.password)
                b-icon.mr-1(icon="shield-lock")
                | {{$t('menu.settings.password.title')}}
                b-modal#password(:title="$t('menu.settings.password.title')", body-class="objects-modal", size="lg", @hide="clearPassword")
                  template.justify-content-between.p-2
                    b-form
                      .d-flex.p-2
                        .h6(style="width: 150px; flex: none;") {{$tc('menu.settings.password.old', 1)}}:
                        b-form-input(v-model='password.oldPass', style="width: auto", :state="password.oldPass !== ''", type="text", :placeholder="$tc('menu.settings.password.old', 2)", :type="'password'", autocomplete="off")
                      .d-flex.p-2
                        .h6(style="width: 150px; flex: none;") {{$t('menu.settings.password.new')}}:
                        b-form-input(v-model='password.newPass', style="width: auto", :state="passwordState", type="text", :placeholder="$t('menu.settings.password.new')", aria-describedby="input-live-password", :type="password.secretNew ? 'text' : 'password'", autocomplete="off")
                        b-input-group-append(style="display: block")
                          b-button(variant="outline-secondary", @click="password.secretNew = !password.secretNew; password.secretConfirm = false")
                            b-icon(:icon="password.secretNew ? 'eye-fill' : 'eye-slash-fill'")
                        b-form-invalid-feedback.p-2#input-live-password
                          |  {{$t('menu.settings.password.description')}}
                      .d-flex.p-2
                        .h6(style="width: 150px; flex: none;") {{$tc('menu.settings.password.replay', 2)}}:
                        b-form-input(v-model='password.confirmPass', style="width: auto", :state="confirmState", type="text", :placeholder="$tc('menu.settings.password.replay', 1)", aria-describedby="input-live-confirm", :type="password.secretConfirm ? 'text' : 'password'", autocomplete="off")
                        b-input-group-append(style="display: block")
                          b-button(variant="outline-secondary", @click="password.secretConfirm = !password.secretConfirm; password.secretNew = false")
                            b-icon(:icon="password.secretConfirm ? 'eye-fill' : 'eye-slash-fill'")
                        b-form-invalid-feedback.p-2#input-live-confirm
                          | {{$t('menu.settings.password.correct')}}
                  template(#modal-footer)
                    b-button(@click="savePassword(password)", variant="success", , size="sm", :disabled="passwordState && confirmState &&  (password.oldPass !== '') ? null : 'disabled'") {{$t('actionsList.save')}}
                    b-button(@click="$bvModal.hide('password')",  variant="secondary", size="sm") {{$t('actionsList.cancel')}}
              b-dd-item-button(v-b-modal.modal-xl.keys)
                b-icon.mr-1(icon="tablet")
                | {{$t('menu.settings.keys.title')}}
                b-modal#keys(:title="$t('menu.settings.keys.title')", body-class="objects-modal", @show="getApiKeys", size="xl", hide-footer)
                  .justify-content-between
                    .py-2
                      b-button(v-if="!createdKey" variant="success", size="sm", @click="createdKey = true") {{$t('menu.settings.keys.create')}}
                      .d-flex.align-items-center.justify-content-between(v-if="createdKey")
                        .h6.mr-2(style="min-width: max-content;") {{$t('menu.settings.keys.period')}}:
                        b-form-datepicker.mr-2(v-model='apiKey.ExpireDate', block, start-weekday="1", locale="ru-RU", style="font-size: 14px;")
                        .h6.mr-2 {{$t('menu.settings.keys.notes')}}:
                        b-form-input.mr-2(v-model='apiKey.Notes', type="text", :placeholder="$t('menu.settings.keys.notes')", style="font-size: 14px;")
                        b-button( variant="success", size="sm", @click="addKey") {{$t('actionsList.create')}}
                    b-table(v-if="apiKeys.length > 0", striped hover, :items="apiKeys", :fields="fieldsForApiKeys")
                      template(v-slot:cell(qrCode)="data")
                        b-button.p-0#popover-target(variant="link")
                          b-icon(icon="upc-scan", variant="dark")
                        b-popover(target="popover-target", triggers="hover", placement="right")
                          template(v-slot:title) {{$t('menu.settings.keys.code')}}
                          vue-qrcode(:value="jsonQrCode(data.item)")
                      template(v-slot:cell(Id)="data")
                        b-button.p-0(variant="link", @click="deleteKey(data.item.Id)")
                          b-icon(icon="trash", variant="danger")
              b-dd-item-button(v-b-modal.modal-xl.locators)
                b-icon.mr-1(icon="binoculars")
                | {{$t('menu.settings.locators.title')}}
                b-modal#locators(
                  :title="$t('menu.settings.locators.title')",
                  size="xl",
                  hide-footer,
                  @show="getLocators",
                  @hidden="hiddenLocators")
                  .mb-4
                    b-button(v-if="!createdLocator" variant="success", size="sm", style="display: block; width: 230px; margin: 0 auto", @click="createdLocator = true") {{$t('menu.settings.locators.create')}}
                    b-container(v-else)
                      b-row.mb-3.align-items-center
                        b-col.pl-0
                          label.mb-0(for="create-locator-name") {{$t('menu.settings.locators.name')}}:
                        b-col.p-0(cols="4")
                          b-form-input(v-model.trim='locator.LocatorName', id="create-locator-name", type="text", maxlength="30", :placeholder="$t('menu.settings.locators.name')", style="font-size: 14px;")
                        b-col
                          label.mb-0(for="create-locator-delete-date") {{$t('menu.settings.locators.period')}}:
                        b-col.p-0(cols="4")
                          b-form-datepicker(v-model='locator.DeleteDate', id="create-locator-delete-date", block, start-weekday="1", locale="ru-RU", style="font-size: 14px;")
                      b-row
                        b-col.pl-0.text-center
                          b-button( variant="info", size="sm", v-b-modal.select-objects-locators) {{locator.ObjectIds.length > 0 ? $t('monitoring.editObjects') : $t('monitoring.selectObjects')}}
                          .mt-2.p-2.area-create(v-if="locator.ObjectIds.length > 0")
                            .d-flex.align-items-start(v-for="item in getObjectsForNewLocator" :key="item.id ? item.id : item.Id")
                              b-avatar(:src="item.data ? item.data.image : item.image", size="sm", variant="light", :icon="(item.data ? item.data.image : item.image) ? null : 'cursor-fill'")
                              span {{ item.data ? item.data.Name : item.Name }}
                              b-badge.ml-1.mr-1(v-if="isAdmin && showAccountingUnits", style="line-height: inherit") {{ item.data ? item.data.AccountingUnitName : item.AccountingUnit.Name }}
                        b-col.text-center
                          b-button( variant="info", size="sm", v-b-modal.select-geofences-locators) {{locator.GeofenceIds.length > 0 ? $t('geofence.editGeofences') : $t('geofence.selectGeofences')}}
                          .mt-2.p-2.area-create(v-if="locator.GeofenceIds.length > 0")
                            .d-flex(v-for="item in getGeofencesForNewLocator" :key="item.id")
                              .pr-2
                                b-icon(:icon="getTypeIcon(item.type)", size="sm", variant="dark")
                              .geofences-list--name.w-100.pr-2.align-items-center.text-truncate.text-left {{ item.Name }}
                        b-col.p-0.text-center
                          b-button(variant="success",
                            size="sm",
                            :disabled="!locator.LocatorName || !locator.DeleteDate || (!locator.ObjectIds.length && !locator.GeofenceIds.length)",
                            @click="createLocator") {{$t('actionsList.create')}}
                  b-table(:busy="!isReady", striped, hover, show-empty, sticky-header="500px", :items="locators", :fields="fieldsForLocators")
                    template(v-slot:cell(LocatorName)="data")
                      span.mr-2 {{ data.item.LocatorName }}
                      b-button(:title="$t('menu.settings.locators.edit')", v-b-tooltip.hover, size="sm", variant="link", @click="getEditedLocator(data.item)")
                        b-icon(icon="pencil", variant="dark")
                    template(v-slot:cell(KeyValue)="data")
                      a.mr-2(:href="getFullFormatedLink(data)", target="_blank") {{ getFullFormatedLink(data) }}
                      b-button(:title="$t('monitoring.copytoclipboard')", v-b-tooltip.hover, size="sm", variant="link", @click="copyLinkToClipboard(getFullFormatedLink(data))")
                        b-icon(icon="files", variant="link")
                    template(v-slot:cell(IsBlocked)="data")
                      b-button(v-if="!data.item.IsBlocked", :title="$t('menu.settings.locators.unblocked')", v-b-tooltip.hover, variant="link", @click="blockLocator(data.item.LocatorId)")
                        b-icon(icon="person-check", variant="success")
                      b-button(v-else, :title="$t('menu.settings.locators.blocked')", v-b-tooltip.hover, variant="link", @click="unblockLocator(data.item.LocatorId)")
                        b-icon(icon="person-x", variant="danger")
                    template(v-slot:table-busy)
                      div.d-flex.align-items-center.justify-content-center.text-info(style="height: 425px")
                        b-spinner.mr-2
                        strong {{$t('login.load') + '...'}}
                    template(v-slot:empty)
                      div.d-flex.align-items-center.justify-content-center.text-info(style="height: 425px")
                        strong {{$t('menu.settings.locators.empty')}}
                  b-modal#select-objects-locators(
                    :title="$tc('object', 2)",
                    size="lg",
                    scrollable,
                    @show.once="getObjectsLocators",
                    @ok="updateObjectsModel")
                    b-tabs.d-flex.flex-column.h-100.list-groups-tabs(v-model="activeListObjectsSubTab", @changed="changedTabs" size="sm", style="width: 100%; font-size: 14px;")
                      b-tab.nav-button(v-if="selectedObjects.length > 0", :title="`${$t('tracks.monitoring')} (${objectsSelectedCount})`", size="sm", :title-link-class="activeListObjectsSubTab === 0 ? ['bg-light', 'text-info'] : ['bg-light', 'text-secondary']", active)
                      b-tab.nav-button(v-if="selectedObjects.length > 0 && viewStorage === 1", :title="`${$t('monitoring.sharedgroup')} (${objectsSelectedCount})`", size="sm", :title-link-class="activeListObjectsSubTab === 1 ? ['bg-light', 'text-info'] : ['bg-light', 'text-secondary']")
                      b-tab.nav-button(v-if="selectedObjects.length > 0 && viewStorage === 2", :title="`${$t('monitoring.privategroup')} (${objectsSelectedCount})`", size="sm", :title-link-class="activeListObjectsSubTab === 1 ? ['bg-light', 'text-info'] : ['bg-light', 'text-secondary']")
                      template(v-if="selectedObjects.length > 0")
                        .flex-column.flex-grow-1.overflow-hidden(style="display: flex" v-show="showListObjects")
                          b-form-input.mx-1.my-2(v-model="objectToFind", style="width: auto", autofocus, type="text", :placeholder="$tc('tracks.search', 2)")
                          list-groups-objects(
                            ref="listGroupsObjectsLocators",
                            :rr="rr",
                            :view="outMonitoringView",
                            :variantList="variantList",
                            :sharedGroups="sharedGroups",
                            :privateGroups="privateGroups",
                            :foundObjects="foundObjects",
                            :objectToFind="objectToFind",
                            :isAdmin="isAdmin",
                            :selectedObjects="selectedObjects",
                            v-on:change-checked-objects="changeCheckedObjects",
                            v-on:on-checked-object="onCheckedObject",
                          )
                      b-tab.nav-button(:title="`${$t('tracks.objects')} (${objectsSelectedModalCount})`", size="sm", :title-link-class="activeListObjectsSubTab === countTabs - 1 ? ['bg-light', 'text-info'] : ['bg-light', 'text-secondary']")
                        .areaObjects.py-2
                          objects-list(:selected="modalSelectedObjects.map(item => item.Id ? item.Id : item)", @change="objectSelectChange")
                    template(v-slot:modal-footer="{ ok }")
                      .d-flex.align-items-center.justify-content-between(style="width: 100%")
                        b-button.mr-2(
                          @click="ok()",
                          variant="success",
                          size="sm",
                          style="width: 50%"
                        ) {{$tc('tracks.add', 1)}}
                        b-button.mr-2(@click="clearSelectedAndCheckedObjects('listGroupsObjectsLocators')",  variant="danger", size="sm", style="width: 50%") {{$t('actionsList.clear')}}
                  b-modal#select-geofences-locators(
                    :title="$tc('geofence.geofence', 2)",
                    size="lg",
                    scrollable,
                    @show.once="getGeofencesLocators",
                    @ok="updateGeofencesModel")
                    b-tabs.d-flex.flex-column.h-100.list-groups-tabs(v-model="activeListGeofencesSubTab", size="sm", style="width: 100%; font-size: 14px;")
                      b-tab.nav-button(v-if="geofences.length > 0", :title="`${$t('monitoring.nongroup')} (${geofencesSelectedCount})`", size="sm", :title-link-class="activeListGeofencesSubTab === 0 ? ['bg-light', 'text-info'] : ['bg-light', 'text-secondary']", active)
                      b-tab.nav-button(v-if="geofences.length > 0 && viewGeofenceStorage === 1", :title="`${$t('monitoring.sharedgroup')} (${geofencesSelectedCount})`", size="sm", :title-link-class="activeListGeofencesSubTab === 1 ? ['bg-light', 'text-info'] : ['bg-light', 'text-secondary']")
                      b-tab.nav-button(v-if="geofences.length > 0 && viewGeofenceStorage === 2", :title="`${$t('monitoring.privategroup')} (${geofencesSelectedCount})`", size="sm", :title-link-class="activeListGeofencesSubTab === 1 ? ['bg-light', 'text-info'] : ['bg-light', 'text-secondary']")
                      template(v-if="geofences.length > 0")
                        b-form-input.mx-1.my-2(v-model="geofenceToFind", style="width: auto", autofocus, type="text", :placeholder="$t('geofence.search')")
                        list-groups-geofences(
                          ref="listGroupsGeofencesLocators",
                          :rr="rr",
                          :viewGeofence="outMonitoringViewGeofence",
                          :variantList="variantList",
                          :sharedGroupsGeofences="sharedGroupsGeofences",
                          :privateGroupsGeofences="privateGroupsGeofences",
                          :foundGeofences="foundGeofences",
                          :geofenceToFind="geofenceToFind",
                          :isAdmin="isAdmin",
                          :geofences="geofences",
                          v-on:change-checked-geofences="changeCheckedGeofences",
                          v-on:on-checked-geofence="onCheckedGeofence",
                        )
                    template(v-slot:modal-footer="{ ok }")
                      .d-flex.align-items-center.justify-content-between(style="width: 100%")
                        b-button.mr-2(
                          @click="ok()",
                          variant="success",
                          size="sm",
                          style="width: 50%"
                        ) {{$tc('tracks.add', 1)}}
                        b-button.mr-2(@click="clearCheckedGeofences('listGroupsGeofencesLocators')",  variant="danger", size="sm", style="width: 50%") {{$t('actionsList.clear')}}
                  b-modal#edit-locators(:title="$t('menu.settings.locators.edittitle')",
                    size="xl",
                    @show.once="getGeofencesLocators",
                    @ok="editLocator",
                    @hidden="hiddenEditLocators")
                    b-container
                      b-row.mb-3.align-items-center
                        b-col.pl-0
                          label.mb-0(for="edit-locator-name") {{$t('menu.settings.locators.name')}}:
                        b-col.p-0(cols="4")
                          b-form-input(v-model.trim='editedLocator.LocatorName', id="edit-locator-name", type="text", maxlength="30", :placeholder="$t('menu.settings.locators.name')", style="font-size: 14px;")
                        b-col
                          label.mb-0(for="edit-locator-delete-date") {{$t('menu.settings.locators.period')}}:
                        b-col.p-0(cols="4")
                          b-form-datepicker(v-model='editedLocator.DeleteDate', id="edit-locator-delete-date", block, start-weekday="1", locale="ru-RU", style="font-size: 14px;")
                      b-row
                        b-col.pl-0.text-center
                          b-button( variant="info", size="sm", v-b-modal.select-objects-locators) {{editedLocator.ObjectIds.length > 0 ? $t('monitoring.editObjects') : $t('monitoring.selectObjects')}}
                          .mt-2.p-2.area-create(v-if="editedLocator.ObjectIds.length > 0")
                            .d-flex.align-items-start(v-for="item in getObjectsForEditedLocator" :key="item.id ? item.id : item.Id")
                              b-avatar(:src="item.data ? item.data.image : item.image", size="sm", variant="light", :icon="(item.data ? item.data.image : item.image) ? null : 'cursor-fill'")
                              span {{ item.data ? item.data.Name : item.Name }}
                              b-badge.ml-1.mr-1(v-if="isAdmin && showAccountingUnits", style="line-height: inherit") {{ item.data ? item.data.AccountingUnitName : item.AccountingUnit.Name }}
                        b-col.text-center
                          b-button( variant="info", size="sm", v-b-modal.select-geofences-locators) {{editedLocator.GeofenceIds.length > 0 ? $t('geofence.editGeofences') : $t('geofence.selectGeofences')}}
                          .mt-2.p-2.area-create(v-if="editedLocator.GeofenceIds.length > 0")
                            .d-flex(v-for="item in getGeofencesForEditedLocator" :key="item.id")
                              .pr-2
                                b-icon(:icon="getTypeIcon(item.type)", size="sm", variant="dark")
                              .geofences-list--name.w-100.pr-2.align-items-center.text-truncate.text-left {{ item.Name }}
                    template(v-slot:modal-footer="{ ok }")
                      .d-flex.align-items-center.justify-content-between(style="width: 100%")
                        b-button.mr-2(
                          :disabled="!editedLocator.LocatorName || !editedLocator.DeleteDate || (!editedLocator.ObjectIds.length && !editedLocator.GeofenceIds.length)",
                          @click="ok()",
                          variant="success",
                          size="sm",
                          style="width: 50%"
                        ) {{$t('actionsList.save')}}
                        b-button.mr-2(@click="clearEditedLocator",  variant="danger", size="sm", style="width: 50%") {{$t('actionsList.clear')}}
              b-dd-item-button(v-b-modal.modal-lg.settings)
                b-icon.mr-1(icon="gear")
                | {{$t('menu.settings.settings.title')}}
                b-modal#settings(
                  :title="$t('menu.settings.settings.title')",
                  body-class="objects-modal",
                  @show="getSettings",
                  size="lg",
                  scrollable)
                  .justify-content-between.p-2
                    .modal-label {{$t('menu.settings.settings.address.view')}}
                    b-form-group.mb-3
                      b-form-radio(value="0", v-model="settings.address") {{$t('menu.settings.settings.address.type1')}}
                      b-form-radio(value="1", v-model="settings.address") {{$t('menu.settings.settings.address.type2')}}
                    .modal-label {{$t('menu.settings.settings.show')}}
                    b-form-group.mb-3
                      b-form-radio(value="1", v-model="settings.report") {{$t('menu.settings.settings.yes')}}
                      b-form-radio(value="0", v-model="settings.report") {{$t('menu.settings.settings.no')}}
                    .modal-label {{$tc('menu.settings.settings.colors', 1)}}
                    b-form-checkbox.mb-3(v-model="settings.colors") {{$tc('menu.settings.settings.colors', 2)}}
                    .modal-label {{$t('menu.settings.settings.titlesize')}}
                    b-form-group.mb-3
                      b-form-radio(value="0.7", v-model.number="settings.size") {{$tc('menu.settings.settings.size', 0)}}
                      b-form-radio(value="0.6", v-model.number="settings.size") {{$tc('menu.settings.settings.size', 1)}}
                      b-form-radio(value="0.5", v-model.number="settings.size") {{$tc('menu.settings.settings.size', 2)}}
                    .modal-label {{$tc('menu.settings.settings.map', 0)}}
                    b-form-checkbox.mb-3(v-model="settings.showMarkersOnMapEdge") {{$tc('menu.settings.settings.map', 1)}}
                    div {{$tc('menu.settings.settings.map', 2)}}: {{ settings.groupingRadius }}
                    b-form-input.mb-3(v-model.number="settings.groupingRadius", type="range", min="10", max="100")
                    b-form-checkbox.mb-3(v-model="settings.trackContour") {{$t('menu.settings.settings.trackContour')}}
                    b-form-checkbox.mb-3(v-model="settings.blurOutdatedMarkers") {{$t('menu.settings.settings.blurOutdatedMarkers')}}
                    .modal-label {{$tc('menu.settings.settings.monitoring', 1)}}
                    b-form-checkbox.mb-3(v-model="settings.monitoringRemoveButton") {{$tc('menu.settings.settings.monitoring', 2)}}
                    .modal-label {{$tc('menu.settings.settings.groupingReports', 1)}}
                    b-form-checkbox.mb-3(v-model="settings.groupingReports") {{$tc('menu.settings.settings.groupingReports', 2)}}
                    template(v-if="isAdmin")
                      .modal-label {{$tc('menu.settings.settings.showAccountingUnits', 1)}}
                      b-form-checkbox.mb-3(v-model="settings.showAccountingUnits") {{$tc('menu.settings.settings.showAccountingUnits', 2)}}
                    .modal-label {{$tc('menu.settings.settings.openFirstTrack', 1)}}
                    b-form-checkbox.mb-3(v-model="settings.openFirstTrack") {{$tc('menu.settings.settings.openFirstTrack', 2)}}
                    .modal-label {{$tc('menu.settings.settings.timeoutPopupCluster', 1)}}
                    .position-relative.mb-4
                      b-form-input.w-50(
                        v-model.number="settings.timeoutPopupCluster",
                        :placeholder="$tc('menu.settings.settings.timeoutPopupCluster', 2)",
                        type="number",
                        step="0.1",
                        min="0",
                        max="2",
                        required,
                        :state="validationTimeoutPopupCluster")
                      b-form-invalid-feedback.position-absolute Допустимый диапзон времени от 0 до 2 секунд
                    .modal-label {{$tc('menu.settings.settings.expandSearchObjects', 1)}}
                    b-form-checkbox.mb-3(v-model="settings.expandSearchObjects") {{$tc('menu.settings.settings.expandSearchObjects', 2)}}
                    .modal-label {{$t('menu.settings.settings.rendering.mode')}}
                    b-form-group.mb-3
                      b-form-radio(value="0", v-model.number="settings.renderingMode") {{$t('menu.settings.settings.rendering.server')}}
                      b-form-radio(value="1", v-model.number="settings.renderingMode") {{$t('menu.settings.settings.rendering.client')}}
                    .modal-label {{$tc('menu.settings.settings.showStopsParkingsInTracks', 0)}}
                    b-form-checkbox(v-model="settings.showStopsInTracks") {{$tc('menu.settings.settings.showStopsParkingsInTracks', 1)}}
                    b-form-checkbox(v-model="settings.showParkingsInTracks") {{$tc('menu.settings.settings.showStopsParkingsInTracks', 2)}}
                  template(#modal-footer)
                    .d-flex.p-2
                      b-button.mr-2(
                        @click="saveSettings",
                        :disabled="!validationTimeoutPopupCluster",
                        variant="success",
                        size="sm") {{$t('actionsList.save')}}
                      b-button(@click="$bvModal.hide('settings')", variant="danger", size="sm") {{$t('actionsList.cancel')}}
              b-dd-item-button
                a(href="http://support.logexpert.ru", rel="nofollow noopener noreferrer", target="_blank", :style="{color: 'black', 'text-decoration': 'none' }")
                  b-icon.mr-1(icon="question")
                  | {{$t('menu.settings.support')}}
              b-dd-item-button(v-b-modal.modal-lg.language)
                b-icon.mr-1(icon="globe")
                | {{$tc('menu.settings.language', 1)}}
                b-modal#language(:title="$tc('menu.settings.language', 2)", centered, body-class="objects-modal", size="sm", @show="createLanguage")
                  template.justify-content-between.p-2
                    b-form-select(v-model="lang" :options="langs")
                  template(#modal-footer)
                    b-button(@click="updateLanguage(lang)", variant="success", size="sm") {{$t('actionsList.save')}}
              b-dd-item-button(v-b-modal.modal-lg.info)
                b-icon.mr-1(icon="info")
                | {{$tc('menu.settings.info', 1)}}
                b-modal#info(:title="$tc('menu.settings.info', 2).toUpperCase()", ok-only, centered, body-class="objects-modal")
                  p Включена в единый реестр российских программ для электронных вычислительных машин и баз данных (реестровая запись №19067 от 18.09.2023)
                  p Версия – 3.15
                  p Правообладатель: ООО "ЭКСПЕРТКОМ" 308015, Белгородская обл., г. Белгород, ул. Свободная, д.50
              b-dd-divider
              b-dropdown-item-button(@click="logout", variant="danger")
                b-icon.mr-1(icon="power")
                | {{$t('menu.settings.out')}}
    .main
      LeMainMap
      nuxt(keep-alive)
      .notification-container.d-none
      b-sidebar#sidebar-right(:backdrop="true" right shadow @shown="showAlerts" size="sm")
        template(v-slot:title)
          .d-flex
            .p-2 {{$t('menu.alerts')}}
            b-button(v-b-tooltip.hover.bottom, variant="light", @click="removeAllAlerts", :title="$t('alldelete')").px-2.py-1
              b-icon(icon="trash-fill", variant="secondary")
        .list(v-if="countAlerts > 0")
          recycle-scroller.list-group.list-group-flush(v-if="alerts && alerts.length > 0", :items="alerts", key-field="Id", v-slot="{ item }",
            :buffer="200", :min-item-size="160")
            b-list-group-item.btn-sm(button, @click="setActiveAlert(item)")
              .d-flex.align-items-center.justify-content-between.p-2
                .image
                  b-avatar(size="sm", variant="light", icon="bell-fill")
                b.objects-name.mx-2 {{ item.Object.Name }}
                a.close(href="#", @click.stop="removeAlert(item.Id)")
                  b-icon.h3(icon="x")
              .d-flex.h-100
                span.objects-name.mx-2 {{ item.MessageText }}
              .p-2(style="text-align: end;")
                span {{ new Date(item.CreateDate * 1000).toLocaleString('ru-RU') }}
          .list.p-3.d-flex.justify-content-center.align-items-center.mb-3(v-else)
            b-spinner(variant="info", large)
        // div(v-if="countAlerts > 0")
          DynamicScroller(v-if="alerts && alerts.length > 0", :items="alerts", :min-item-size="120", class="scroller", key-field="Id")
            template(v-slot="{ item, index, active }")
              DynamicScrollerItem(:item="item", :active="active", :data-index="index", :size-dependencies="[ item.MessageText]")
                b-list-group-item.btn-sm(button, @click="setActiveAlert(item)")
                  .d-flex.align-items-center.justify-content-between.p-2
                    .image
                      b-avatar(size="sm", variant="light", icon="bell-fill")
                    b.objects-name.mx-2 {{ item.Object.Name }}
                    a.close(href="#", @click.stop="removeAlert(item.Id)")
                      b-icon.h3(icon="x")
                  .d-flex.h-100
                    span.objects-name.mx-2 {{ item.MessageText }}
                  .p-2(style="text-align: end;")
                    span {{ new Date(item.CreateDate * 1000).toLocaleString() }}
          .list.p-3.d-flex.justify-content-center.align-items-center.mb-3(v-else)
            b-spinner(variant="info", large)
</template>
<script>
import vueCustomScrollbar from 'vue-custom-scrollbar';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import VueQrcode from 'vue-qrcode';
import listGroupsObjectsInteraction from '@/mixins/listGroupsObjectsInteraction';
import listGroupsGeofencesInteraction from '@/mixins/listGroupsGeofencesInteraction';
import LeMainMap from '~/components/Le/Main/';
import ObjectsList from '~/components/Le/ObjectsList';

export default {
  name: 'Default',
  components: {
    LeMainMap,
    vueCustomScrollbar,
    VueQrcode,
    ObjectsList,
  },
  mixins: [listGroupsObjectsInteraction, listGroupsGeofencesInteraction],
  data () {
    return {
      mqtt: global.$nuxt.$mqtt,
      topMenu: [],
      // scrollSettings: {
      //   minScrollbarLength: 60,
      // },
      settings: {
        address: 0,
        report: 1,
        colors: false,
        size: 0.7,
        groupingReports: false,
        showAccountingUnits: true,
        openFirstTrack: true,
        timeoutPopupCluster: 0.5,
        expandSearchObjects: false,
        renderingMode: 0,
        showStopsInTracks: true,
        showParkingsInTracks: true,
        showMarkersOnMapEdge: true,
        groupingRadius: 50,
        trackContour: true,
        blurOutdatedMarkers: false,
        monitoringRemoveButton: false,
      },
      isRed: true,
      accountingUnitId: null,
      // currentAccountingUnit: null,
      createdKey: false,
      createdLocator: false,
      apiKey: {
        KeyValue: '',
        ExpireDate: new Date(new Date(new Date().setYear(new Date().getFullYear() + 1)).toDateString()),
        Notes: null,
      },
      locator: {
        LocatorName: '',
        DeleteDate: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(new Date().getHours() + 3)).toISOString(),
        AccountingUnitId: null,
        ObjectIds: [],
        GeofenceIds: [],
      },
      openModalEditLocator: false,
      editedLocator: {
        LocatorName: '',
        DeleteDate: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(new Date().getHours() + 3)).toISOString(),
        AccountingUnitId: null,
        ObjectIds: [],
        GeofenceIds: [],
      },
      fieldsForApiKeys: [],
      fieldsForLocators: [],
      password: {
        oldPass: '',
        newPass: '',
        confirmPass: '',
        secretNew: false,
        secretConfirm: false,
      },
      lang: '',
      langs: [],
      createModalSelectedObjects: [],
      editedModalSelectedObjects: [],
    };
  },
  computed: {
    ...mapState(['isDragged']),
    ...mapGetters('notify', { lastNotify: 'lastNotify', countNotify: 'countNotify' }),
    ...mapGetters('alerts', { countAlerts: 'countAlerts', alerts: 'alerts', update: 'refresh' }),
    ...mapGetters('aUnits', { accountingUnits: 'accountingUnits' }),
    ...mapGetters('preferences', { settingsBase: 'settingsAUnit', settingsMap: 'settingsMap', showAccountingUnits: 'showAccountingUnits', monitoringRemoveButton: 'monitoringRemoveButton' }),
    ...mapGetters('apiKeys', { apiKeys: 'apiKeys' }),
    ...mapGetters('locators', ['locators', 'isReady', 'allLocatorsObjectIds']),
    ...mapGetters('objects', { objectsCount: 'arrItemsCount', objects: 'arrItems' }),
    validationTimeoutPopupCluster () {
      return this.settings.timeoutPopupCluster === 0 || (this.settings.timeoutPopupCluster > 0 && this.settings.timeoutPopupCluster <= 2);
    },
    mqttStatus () {
      return this.mqtt.connected;
    },
    getObjectsForNewLocator () {
      return [...this.createModalSelectedObjects, ...this.objects.filter(item => this.locator.ObjectIds.find(_item => _item === item.id))];
    },
    getGeofencesForNewLocator () {
      return this.geofences.filter(item => this.locator.GeofenceIds.find(_item => _item === item.Id));
    },
    getObjectsForEditedLocator () {
      return [...this.editedModalSelectedObjects, ...this.objects.filter(item => this.editedLocator.ObjectIds.find(_item => _item === item.id))];
    },
    getGeofencesForEditedLocator () {
      return this.geofences.filter(item => this.editedLocator.GeofenceIds.find(_item => _item === item.Id));
    },
    isAdminAU () {
      const { isAdminAU } = this.$store.state.user.data;
      return isAdminAU;
    },
    dragged () {
      return this.isDragged ? {
        dragged: true,
        [this.isDragged]: true,
      } : null;
    },
    toAdminApp () {
      return `${process.env.ADMIN_ADDRESS}/?token=${this.$store.state.user.token}`;
    },
    passwordState () {
      return (this.password.newPass.length > 5) && this.password.newPass.match(/[a-z]/g) !== null && this.password.newPass.match(/[A-Z]/g) !== null && this.password.newPass.match(/[0-9]/g) !== null;
    },
    confirmState () {
      return this.password.confirmPass !== '' && this.password.confirmPass === this.password.newPass;
    },
    accountingUnit () {
      if (!this.isAdminAU) {
        return null;
      }

      // eslint-disable-next-line
      // this.accountingUnitId = this.$store.state.user.data.AccountingUnitId;
      const unit = this.accountingUnits.find(x => x.Id === this.accountingUnitId)?.Name;
      // const unit = this.accountingUnits.find(x => x.Id === this.currentAccountingUnit)?.Name;
      return unit || null;
    },
  },
  watch: {
    accountingUnitId (n) {
      this.locator.AccountingUnitId = n;
      this.editedLocator.AccountingUnitId = n;
    },
    lastNotify: {
      handler (value) {
        if (value) {
          this.toast(value.massage, value.type, value.alertId);
          setTimeout(() => {
            const headersForToasts = document.querySelectorAll('.toast-header');
            const notifyLength = headersForToasts.length;

            if (this.countNotify > 10 && notifyLength > 10) {
              headersForToasts.forEach((item, index) => {
                if (index < notifyLength - 10) {
                  const currentButton = item.querySelector('button');
                  currentButton.dispatchEvent(new Event('click'));
                }
              });
            }
            const successToasts = document.querySelectorAll('.b-toast-success');
            const prefix = 'alertId-';
            successToasts.forEach((item) => {
              if (item.firstChild && item.firstChild.firstChild && item.firstChild.firstChild.lastChild) {
                // eslint-disable-next-line no-param-reassign
                item.firstChild.firstChild.lastChild.onclick = (e) => {
                  e.stopPropagation();
                };
                // eslint-disable-next-line no-param-reassign
                item.onclick = (e) => {
                  const strAlertId = Array.from(item.firstChild.classList).find(_item => _item.startsWith(prefix));
                  this.getCoordinatesAlert(Number(strAlertId.slice(prefix.length)));
                };
              }
            });
          }, 300);
        }
      },
      deep: true,
    },
    objectsCount (value) {
      this.$store.dispatch('alerts/getAlertsCount');
    },
    update (value) {
      if (value) {
        this.isRed = true;
      }
    },
  },
  mounted () {
    this.init();
    this.accountingUnitId = this.$store.state.user.data.AccountingUnitId;
    this.setView(localStorage.view ? JSON.parse(localStorage.view) : 0);
    this.setViewGeofence(localStorage.viewGeofence ? JSON.parse(localStorage.viewGeofence) : 0);
  },
  methods: {
    ...mapActions('preferences', ['setPreference']),
    ...mapMutations('listGroupsObjects', ['setView']),
    ...mapMutations('listGroupsGeofences', ['setViewGeofence']),
    hiddenEditLocators () {
      this.clearEditedLocator();
      this.openModalEditLocator = false;
    },
    async getCoordinatesAlert (id) {
      const data = await this.$store.dispatch('alerts/getAlert', id);
      if (data && data.Latitude && data.Longitude) {
        const coordinates = {
          lat: data.Latitude,
          lng: data.Longitude,
        };
        this.$store.commit('map/setCenter', coordinates);
      }
    },
    async getLocatorsObjectsList (ids) {
      let objectsList = [];
      if (ids.length) {
        // eslint-disable-next-line no-return-await
        objectsList = await this.$store.dispatch('objects/fetchObjectsIds', ids).then(async (objects) => {
          await this.generateObjectImages(objects);
          return objects;
        });
      }
      return objectsList;
    },
    editLocator () {
      this.$store.dispatch('locators/editLocator', { locator: this.editedLocator, id: this.editedLocator.LocatorId });
    },
    clearEditedLocator () {
      this.editedLocator = {
        LocatorName: '',
        DeleteDate: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(new Date().getHours() + 3)).toISOString(),
        AccountingUnitId: null,
        ObjectIds: [],
        GeofenceIds: [],
      };
    },
    async getEditedLocator (locator) {
      this.editedLocator = await this.$store.dispatch('locators/getLocator', locator.LocatorId);
      this.editedLocator = { ...this.editedLocator, ...{ DeleteDate: new Date(this.editedLocator.DeleteDate * 1000).toISOString() } };
      this.changeCheckedObjects(this.editedLocator.ObjectIds.filter(item => this.objects.find(_item => _item.id === item)));
      const modalSelectedObjects = this.editedLocator.ObjectIds.filter(item => !this.objects.find(_item => _item.id === item));
      this.editedModalSelectedObjects = await this.getLocatorsObjectsList(modalSelectedObjects);
      this.modalSelectedObjects = this.editedModalSelectedObjects;
      this.changeCheckedGeofences(this.editedLocator.GeofenceIds);
      this.$bvModal.show('edit-locators');
      this.openModalEditLocator = true;
    },
    copyLinkToClipboard (link) {
      if (window.isSecureContext) {
        // navigator clipboard api method'
        window.navigator.clipboard.writeText(link);
      } else {
        // text area method
        const textArea = document.createElement('textarea');
        textArea.value = link;
        // make the textarea out of viewport
        textArea.style.position = 'absolute';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        textArea.style.opacity = '0';
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        textArea.remove();
      }
    },
    getFullFormatedLink (data) {
      // eslint-disable-next-line
      return window.location.origin + '/locator/?apikey=' + data.item.KeyValue;
    },
    hiddenLocators () {
      this.createModalSelectedObjects = [];
      this.editedModalSelectedObjects = [];
      this.modalSelectedObjects = [];
      this.clearNewLocator();
      this.$store.commit('locators/setIsReady', false);
    },
    clearNewLocator () {
      this.locator = {
        LocatorName: '',
        DeleteDate: new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setHours(new Date().getHours() + 3)).toISOString(),
        AccountingUnitId: null,
        ObjectIds: [],
        GeofenceIds: [],
      };
    },
    updateObjectsModel () {
      const objects = this.checkedObjects.concat(this.modalSelectedObjects.map(el => el.Id));
      if (this.openModalEditLocator) {
        this.editedLocator.ObjectIds = objects;
        this.editedModalSelectedObjects = this.modalSelectedObjects;
      } else {
        this.locator.ObjectIds = objects;
        this.createModalSelectedObjects = this.modalSelectedObjects;
      }
    },
    getAllObjects () {
      this.getInfoAboutAllGroups();
      this.generateObjectImages(this.objects);
    },
    objectSelectChange (val) {
      this.modalSelectedObjects = val;
    },
    changeCheckedObjects (arr) {
      this.checkedObjects = arr;
      this.setCheckedObjectsOutMonitoring(JSON.parse(JSON.stringify(this.checkedObjects)));
    },
    changeCheckedGeofences (arr) {
      this.checkedGeofences = arr;
      this.setCheckedGeofencesOutMonitoring(JSON.parse(JSON.stringify(this.checkedGeofences)));
    },
    async getAllGeofences () {
      await this.$store.dispatch('geofences/getGeofences', this.accountingUnitGeofences);
      // await this.$store.dispatch('geofences/getDeletedGeofences', this.currentAccountingUnitGeofence);
      await this.$store.dispatch('sharedgeo/getSharedGeofences', this.accountingUnitGeofences);
      await this.$store.dispatch('privategeo/getPrivateGeofences');
      this.geofences = this.convertItemsGeofences(this.items);
      // this.geofencesDel = this.convertItemsGeofences(this.itemsDel);
      this.sharedGroupsGeofences = this.getGroupsGeofences(this.geofences, this.sharedGeofences);
      this.privateGroupsGeofences = this.getGroupsGeofences(this.geofences, this.privateGeofences, false);
    },
    updateGeofencesModel () {
      if (this.openModalEditLocator) {
        this.editedLocator.GeofenceIds = this.checkedGeofences;
      } else {
        this.locator.GeofenceIds = this.checkedGeofences;
      }
    },
    logout (evt) {
      evt.preventDefault();
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push('/login/');
      });
    },
    init () {
      this.topMenu = [
        {
          name: this.$t('menu.monitoring'),
          url: '/monitoring/',
          id: 1,
        },
        {
          name: this.$t('menu.reporting'),
          url: '/reporting/',
          id: 2,
        },
        {
          name: this.$t('menu.tracks'),
          url: '/tracks/',
          id: 3,
        },
        {
          name: this.$t('menu.geofences'),
          url: '/geofences/',
          id: 4,
        },
        {
          name: this.$t('menu.alerts'),
          url: '/alerts/',
          id: 5,
        },
      ];
      this.fieldsForApiKeys = [
        {
          key: 'KeyValue',
          label: this.$t('menu.settings.keys.key'),
          sortable: true,
        },
        {
          key: 'qrCode',
          label: this.$t('menu.settings.keys.code'),
        },
        {
          key: 'CreateDate',
          label: this.$t('menu.settings.keys.date'),
          sortable: true,
          formatter: value => new Date(value * 1000).toLocaleDateString('ru-RU'),
        },
        {
          key: 'ExpireDate',
          label: this.$t('menu.settings.keys.period'),
          sortable: true,
          formatter: value => new Date(value * 1000).toLocaleDateString('ru-RU'),
        },
        {
          key: 'Notes',
          label: this.$t('menu.settings.keys.notes'),
        },
        {
          key: 'Id',
          label: '',
        },
      ];
      this.fieldsForLocators = [
        {
          key: 'LocatorName',
          label: this.$t('menu.settings.locators.name'),
          sortable: true,
        },
        {
          key: 'KeyValue',
          label: this.$t('menu.settings.locators.link'),
          sortable: true,
        },
        {
          key: 'CreateDate',
          label: this.$t('menu.settings.locators.date'),
          sortable: true,
          // eslint-disable-next-line
          formatter: value => value ? new Date(value * 1000).toLocaleDateString('ru-RU') : '-',
        },
        {
          key: 'DeleteDate',
          label: this.$t('menu.settings.locators.period'),
          sortable: true,
          // eslint-disable-next-line
          formatter: value => value ? new Date(value * 1000).toLocaleDateString('ru-RU') : '-',
        },
        {
          key: 'LastLoginDateTime',
          label: this.$t('menu.settings.locators.login'),
          sortable: true,
          // eslint-disable-next-line
          formatter: value => value ? new Date(value * 1000).toLocaleDateString('ru-RU') : '-',
        },
        {
          key: 'ObjectCount',
          label: this.$tc('object', 2),
          formatter: value => value || '-',
        },
        {
          key: 'GeofenceCount',
          label: this.$t('menu.geofences'),
          formatter: value => value || '-',
        },
        {
          key: 'IsBlocked',
          label: this.$t('menu.settings.locators.status'),
        },
      ];
    },
    async getAlertsAccount (accountingUnit) {
      this.isRed = false;
      this.accountingUnitId = accountingUnit;
      await this.$store.dispatch('alerts/getAlerts');
    },
    removeAlert (id) {
      this.$store.dispatch('alerts/removeAlert', id);
    },
    removeAllAlerts () {
      if (this.alerts.length > 0) {
        this.$store.dispatch('alerts/removeAlertsAll', this.objects.map(x => x.id));
      }
    },
    async setActiveAlert (item) {
      const alertInfo = await this.$store.dispatch('alerts/getAlert', item.Id);
      if (alertInfo && alertInfo.Latitude && alertInfo.Longitude) {
        const value = {
          lat: alertInfo.Latitude,
          lng: alertInfo.Longitude,
        };
        this.$store.commit('map/setCenter', value);
      }
    },
    saveSettings () {
      let settings = {
        addressView: this.settings.address,
        showSettingsInReport: this.settings.report,
        useAlternativeStateColors: this.settings.colors,
        monitoringIconSize: this.settings.size,
        groupingReports: this.settings.groupingReports,
        openFirstTrack: this.settings.openFirstTrack,
        timeoutPopupCluster: this.settings.timeoutPopupCluster,
        expandSearchObjects: this.settings.expandSearchObjects,
        renderingMode: this.settings.renderingMode,
        showStopsInTracks: this.settings.showStopsInTracks,
        showParkingsInTracks: this.settings.showParkingsInTracks,
        showMarkersOnMapEdge: this.settings.showMarkersOnMapEdge,
        groupingRadius: this.settings.groupingRadius,
        'track-contour': this.settings.trackContour ? 1 : 0,
        blurOutdatedMarkers: this.settings.blurOutdatedMarkers,
        monitoringRemoveButton: this.settings.monitoringRemoveButton,
      };
      if (this.isAdmin) {
        settings = { ...settings, showAccountingUnits: this.settings.showAccountingUnits };
      }
      this.setPreference(settings);
      this.$bvModal.hide('settings');
    },
    getSettings () {
      this.settings.address = this.settingsBase.addressView;
      this.settings.report = this.settingsBase.showSettingsInReport;
      this.settings.colors = this.settingsBase.useAlternativeStateColors;
      this.settings.size = this.settingsBase.monitoringIconSize;
      this.settings.groupingReports = this.settingsBase.groupingReports;
      this.settings.openFirstTrack = this.settingsBase.openFirstTrack;
      this.settings.timeoutPopupCluster = this.settingsBase.timeoutPopupCluster;
      this.settings.expandSearchObjects = this.settingsBase.expandSearchObjects;
      this.settings.renderingMode = this.settingsBase.renderingMode;
      this.settings.showStopsInTracks = this.settingsBase.showStopsInTracks;
      this.settings.showParkingsInTracks = this.settingsBase.showParkingsInTracks;
      this.settings.showMarkersOnMapEdge = this.settingsMap.showMarkersOnMapEdge;
      this.settings.groupingRadius = this.settingsMap.groupingRadius;
      this.settings.trackContour = this.settingsMap.trackContour;
      this.settings.blurOutdatedMarkers = this.settingsMap.blurOutdatedMarkers;
      this.settings.monitoringRemoveButton = this.monitoringRemoveButton;
      if (this.isAdmin) {
        this.settings.showAccountingUnits = this.settingsBase.showAccountingUnits;
      }
      this.apiKey.ExpireDate = new Date(new Date(new Date().setYear(new Date().getFullYear() + 1)).toDateString());
      this.apiKey.Notes = null;
    },
    getApiKeys () {
      this.createdKey = false;
      this.$store.dispatch('apiKeys/fetchItems');
    },
    async getLocators () {
      this.createdLocator = false;
      await this.$store.dispatch('locators/getLocators');
    },
    getObjectsLocators () {
      this.getAllObjects();
    },
    async getGeofencesLocators () {
      await this.getAllGeofences();
    },
    addKey () {
      this.createdKey = false;
      this.$store.dispatch('apiKeys/createdKey', this.apiKey);
      this.apiKey.ExpireDate = new Date(new Date(new Date().setYear(new Date().getFullYear() + 1)).toDateString());
      this.apiKey.Notes = null;
    },
    createLocator () {
      this.createdLocator = false;
      this.$store.dispatch('locators/createLocator', this.locator);
      this.clearNewLocator();
    },
    deleteKey (id) {
      this.$store.dispatch('apiKeys/deleteKey', id);
    },
    blockLocator (id) {
      this.$store.dispatch('locators/blockLocator', id);
    },
    unblockLocator (id) {
      this.$store.dispatch('locators/unblockLocator', id);
    },
    jsonQrCode (value) {
      const { UserName } = this.$store.state.user.data;
      const json = JSON.stringify({
        UserName,
        KeyValue: value.KeyValue,
        CreateDate: value.CreateDate,
        ExpireDate: value.ExpireDate,
      });
      return json;
    },
    clearPassword () {
      this.password.oldPass = '';
      this.password.newPass = '';
      this.password.confirmPass = '';
    },
    savePassword (password) {
      this.$store.dispatch('user/changePassword', password);
      this.$bvModal.hide('password');
    },
    toast (massage, type, alertId = null) {
      let title = this.$tc('menu.settings.notyfy', 0);
      switch (type) {
        case 'success':
          title = this.$tc('menu.settings.notyfy', 1);
          this.isRed = true;
          break;
        case 'danger':
          title = this.$tc('menu.settings.notyfy', 2);
          break;
        case 'warning':
          title = this.$tc('menu.settings.notyfy2', 0);
          break;
        default:
          break;
      }
      const h = this.$createElement;
      const vNodesTitle = h(
        'div',
        { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'], ref: 'titleForToast' },
        [
          h('strong', { class: 'mr-2' }, title),
          h('small', { class: 'ml-auto text-italics' }, new Date().toLocaleString('ru-RU')),
        ],
      );

      const noAutoHide = type !== 'success';

      this.$bvToast.toast(massage, {
        title: [vNodesTitle],
        variant: type,
        toaster: 'b-toaster-bottom-right',
        toastClass: `alertId-${String(alertId)}`,
        solid: true,
        appendToast: true,
        noAutoHide,
      });
    },
    showAlerts () {
      this.isRed = false;
      if (this.update) {
        this.getAlertsAccount(this.accountingUnitId);
      }
    },
    createLanguage () {
      this.lang = this.settingsBase.language;
      this.langs = this.$i18n.locales.map(l => ({
        value: l.code,
        text: l.name,
      }));
    },
    updateLanguage (lang) {
      this.$i18n.setLocale(lang);
      this.setPreference({ languageUser: lang });
      this.init();
      setTimeout(() => {
        this.$store.commit('objects/clearAllAddress');
      }, 500);
    },
  },
};
</script>

<style lang="stylus">
body {
  overflow: hidden;
}
.root {
  &.dragged {
    //pointer-events: none !important;
    user-select: none !important;
    .grid-container .top {
      pointer-events: auto !important;
    }
    * {
      pointer-events: none !important;
      user-select: none !important;
    }
  }
  &.left {
    cursor: ew-resize !important;
  }
  &.right {
    cursor: ns-resize !important;
  }
}
.close:not(:disabled):not(.disabled) {
  &:focus {
    opacity: .5;
  }
  &:hover {
    opacity: .75;
  }
}
#select-objects-locators > .modal-dialog
  height: 100vh
#select-geofences-locators > .modal-dialog
  height: 100vh
</style>
<style lang="stylus" scoped>
.areaObjects {
  height: 60vh;
  overflow: auto;
}
.area-create {
  overflow: auto;
  max-height: 200px;
  display: flex;
  flex-wrap: wrap;
}
.notification-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  // background-color: #f0f8ff8f;
  // backdrop-filter: blur(2px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255,255,255,0.72);
}
.root {
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
}

.header {
  min-height: 54px;
  user-select: none;
}

.logo-top {
  pointer-events: none;
  user-select: none;
}

.main {
  flex: 1;
  position: relative;
}

.alert_notify {
  text-align: center;
}

.menu-el {
  white-space: nowrap;
}

.image {
  float: left;
  min-width: 30px;
  max-width: 30px;
}

.modal-label {
  font-size: 14px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  line-height: 1;
  color: #677079;
}
.modal-label:after {
  content: '';
  display: block;
  border-top: 1px dotted #9da6b0;
  flex: 1;
  margin-left: 5px;
}

.mqtt-status {
  width: 10px;
  height: 10px;
  margin-right: 15px;
  border-left: 2px solid black;
  border-right: 2px solid black;
  border-radius: 50%;
  background-color: #28a745;
  transition: 0.3s;
}
.mqtt-status_disconnect {
  background-color: #dc3545;
}
.alerts-counter {
  position: absolute;
  top: -10px;
  right: 0;
  text-align: center;
  font-size: 16px;

  & > * {
    left: 50%;
  }
}
</style>
