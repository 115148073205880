<template lang="pug">
  div
    l-tile-layer(v-for="layer in layers.filter(x => x.type === 'osm' && x.visible)",
      v-bind.sync="layer",
      :key="'layer'+layer.name",
      @tileerror="handleTileError"
      @ready="readyLayer")
    l-google-tile-layer(v-for="layer in layers.filter(x => x.type === 'gmaps' && x.options.type && x.visible)",
      :apikey="gmapsConfig.key",
      v-bind.sync="layer",
      :key="'gmap_'+layer.name"
      @ready="readyLayer")
    l-yandex-tile-layer(v-for="layer in layers.filter(x => x.type === 'yandex' && x.options.type && x.visible)",
      :apikey="yandexConfig.key",
      v-bind.sync="layer",
      :key="'ymap_'+layer.name"
      @ready="readyLayer")
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'LeLayers',
  props: {
    gmapsConfig: {
      type: Object,
      default: () => ({
        key: '',
      }),
    },
    yandexConfig: {
      type: Object,
      default: () => ({
        key: '',
      }),
    },
  },
  computed: {
    ...mapState('map', { layers: 'baseLayers' }),
  },
  methods: {
    ...mapMutations('map', ['setResolveAddMarkers']),
    readyLayer () {
      this.setResolveAddMarkers(true);
    },
    handleTileError (evt) {
      if (evt.tile.src === 'https://osm2.logexpert.ru/tile') {
        const url2 = `${evt.tile.src}/{z}/{x}/{y}.png`;
        let tileSrc = url2.replace(/{x}/g, evt.coords.x);
        tileSrc = tileSrc.replace(/{y}/g, evt.coords.y);
        tileSrc = tileSrc.replace(/{z}/g, evt.coords.z);
        // eslint-disable-next-line no-param-reassign
        evt.tile.src = tileSrc;
      }
    },
  },
};
</script>

<style lang="stylus">

</style>
